// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-ts": () => import("./../src/pages/404.ts" /* webpackChunkName: "component---src-pages-404-ts" */),
  "component---src-pages-index-ts": () => import("./../src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-pages-kontakt-ts": () => import("./../src/pages/kontakt.ts" /* webpackChunkName: "component---src-pages-kontakt-ts" */),
  "component---src-pages-oferta-ts": () => import("./../src/pages/oferta.ts" /* webpackChunkName: "component---src-pages-oferta-ts" */),
  "component---src-pages-psychoterapia-analityczna-ts": () => import("./../src/pages/psychoterapia-analityczna.ts" /* webpackChunkName: "component---src-pages-psychoterapia-analityczna-ts" */)
}

